import { Float } from '@headlessui-float/react';
import { Popover } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';

const HoverPopover = ({ button, children, ...props }: any) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const timeoutDuration = 200;

  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const onMouseEnter = () => {
    console.log('onMouseEnter');
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    setOpen(true);
  };

  const onMouseLeave = () => {
    console.log('onMouseLeave');
    timeoutIdRef.current = setTimeout(() => {
      setOpen(false);
    }, timeoutDuration);
  };
  // Clear the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return (
    <Popover className="relative" {...props}>
      {({ open }) => (
        <Float
          shift={2}
          flip={2}
          show={open}
          placement="bottom-end"
          offset={2}
          enter="transition ease-out duration-150"
          enterFrom="transform scale-0 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition ease-in duration-150"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-0 opacity-0"
        >
          <Popover.Button
            ref={buttonRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {button}
          </Popover.Button>
          <Popover.Panel
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="z-10 rounded-md p-2 top-30 right-0 bg-base-100 dark:bg-gray-800 text-base-content shadow-box-lg overflow-hidden"
          >
            {children}
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};

export default HoverPopover;
