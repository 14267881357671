import { appConfig } from '@/config/app';
import { PRODUCT_BADGE_STYLE_BY_STATUS } from '@/config/constants';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

const ProductsPanel = () => {
  const t = useTranslations('ProductsGrid');

  return (
    <>
      {appConfig.map(({ key, path, status, icon: Icon }) => (
        <Popover.Button
          as="a"
          key={key}
          href={path}
          className={`group flex p-3 items-center hover:bg-base-content/5 rounded-md`}
        >
          <Icon className="p-2 rounded-md bg-secondary/5 text-secondary/80 group-hover:bg-secondary/10 group-hover:text-secondary w-11 h-11 mx-1" />
          <div className="ml-4 flex flex-col gap-2">
            <div className="w-full flex flex-row justify-between">
              <span className="lg:font-bold">{t(`${key}.title`)}</span>
              <span
                className={clsx(
                  'badge badge-sm',
                  PRODUCT_BADGE_STYLE_BY_STATUS[status],
                )}
              >
                {t(`product-status.${status}`)}
              </span>
            </div>
            <div className="w-48 lg:w-64 text-sm line-clamp-2">
              {t(`${key}.description`)}
            </div>
          </div>
        </Popover.Button>
      ))}
    </>
  );
};

export default ProductsPanel;
