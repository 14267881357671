import { FeatureStatus } from '@/config/constants';
import CreditCardIcon from '@heroicons/react/24/outline/CreditCardIcon';
import HomeIcon from '@heroicons/react/24/outline/HomeIcon';
import KeyIcon from '@heroicons/react/24/outline/KeyIcon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';

// This configuration is used in Navbar.

export const adminConfig = [
  {
    key: 'DashboardPage',
    name: 'Dashboard',
    path: '/admin/dashboard',
    icon: HomeIcon,
    status: FeatureStatus.Stable,
  },
  {
    key: 'ProfilePage',
    name: 'Profile',
    path: '/admin/profile',
    icon: UserIcon,
    status: FeatureStatus.Stable,
  },
  {
    key: 'ApiKeysPage',
    name: 'ApiKeys',
    path: '/admin/api-keys',
    icon: KeyIcon,
    status: FeatureStatus.Beta,
  },
  // {
  //   key: 'messages',
  //   name: 'Messages',
  //   path: '/admin/messages',
  //   icon: BellIcon,
  // },
  // {
  //   key: 'data',
  //   name: 'Data',
  //   path: '/admin/analytics',
  //   icon: ChartBarIcon,
  // },
  {
    key: 'BillingPage',
    name: 'Billing',
    path: '/admin/billing',
    icon: CreditCardIcon,
    status: FeatureStatus.Stable,
  },
  // The feature of sub user management is not needed for the early phase.
  // {
  //   key: 'users',
  //   name: 'Users',
  //   path: '/admin/users',
  //   icon: UsersIcon,
  // },
  // {
  //   key: 'setting',
  //   name: 'Settings',
  //   path: '/admin/settings',
  //   icon: Cog6ToothIcon,
  // },
] as const;
