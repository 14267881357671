'use client';

import { LOCALE_CONFIG, LOCALES } from '@/config/constants';
import { Popover, Transition } from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import LanguageIcon from '@heroicons/react/24/outline/LanguageIcon';
import clsx from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import { usePathname } from 'next-intl/client';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Fragment, useTransition } from 'react';

export default function LocaleSwitcher() {
  const t = useTranslations('LocaleSwitcher');
  const [isPending, startTransition] = useTransition();
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();

  const onChangeLocale = (l: keyof typeof LOCALE_CONFIG) => {
    startTransition(() => {
      router.replace(`/${l}${pathname}`);
    });
  };

  return (
    <Popover className="relative">
      <Popover.Button
        className="btn btn-sm btn-ghost px-1 flex-nowrap"
        data-tooltip-content={t('tooltip')}
        data-tooltip-id="default-tooltip"
      >
        <LanguageIcon className="w-5 h-5" />
        <ChevronDownIcon className="w-3 h-3" />
      </Popover.Button>
      <Transition
        enter="transition duration-300 ease-out"
        enterFrom="transform scale-80 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-80 opacity-0"
        className="absolute z-50 rounded-md top-10 p-2 right-0 bg-base-100 dark:bg-gray-800 text-base-content shadow-lg overflow-hidden"
      >
        <Popover.Panel className="flex flex-col gap-2">
          {LOCALES.map(cur => (
            <Popover.Button
              key={cur}
              onClick={() => onChangeLocale(cur)}
              className={clsx(`flex gap-3 p-2 w-48 p-2 rounded-md items-center hover:bg-base-content/10`, {
                'bg-base-content/10': cur === locale,
              })}
            >
              <Image
                className="drop-shadow"
                loading="lazy"
                width="20"
                height="20"
                alt={t('locale', { locale: cur })}
                src={LOCALE_CONFIG[cur].flag}
              />
              {t('locale', { locale: cur })}
              {LOCALE_CONFIG[cur].status && (
                <span className="badge badge-sm badge-ghost">{t(LOCALE_CONFIG[cur].status!)}</span>
              )}
            </Popover.Button>
          ))}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
