import pocketbase from '@/utils/pocketbase';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useState } from 'react';
import { BsDiscord } from 'react-icons/bs';
import { toast } from 'react-toastify';

const SupportPanel = () => {
  const t = useTranslations('Navbar');
  const [feedback, setFeedback] = useState('');

  const onFeedbackClick = () => {
    fetch('/api/messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        content: { type: 'text', text: feedback },
        type: 'feedback',
        from: pocketbase.authStore.model?.id,
        to: 'aisd2t9vjv0ialp', // Penless
      }),
    }).then(() => {
      toast(t('submit-feedback-success'));
    });
  };

  return (
    <Popover.Panel className="w-80 p-3 flex flex-col gap-2 text-sm">
      <div className="font-bold">{t('feedback')}</div>
      <div className="flex items-center justify-around nowrap">
        <Image
          data-tooltip-content={t('join-wechat-group')}
          data-tooltip-id="default-tooltip"
          alt="join-wechat-group"
          src="/images/wecom-qr-code.png"
          width={512}
          height={512}
          className="w-32 h-32 object-fit"
        />
        <Popover.Button
          as="a"
          target="_blank"
          href={'https://discord.com/invite/d6gx8bWjUJ'}
          className={`w-36 h-36 rounded-md flex flex-col gap-2 items-center justify-center hover:bg-secondary-content hover:text-secondary`}
        >
          <BsDiscord className="w-10 h-10" />
          <div className="text-center">{t('join-discord-server')}</div>
        </Popover.Button>
      </div>
      <div className="divider my-0" />
      <textarea
        rows={3}
        placeholder={t('feedback-placeholder')}
        value={feedback}
        onChange={e => setFeedback(e.target.value)}
        className={clsx(
          'w-full p-2 rounded-md text-base-content bg-base-200 hover:ring-primary/20 focus:ring-1',
        )}
      />
      <Popover.Button
        onClick={onFeedbackClick}
        className={`btn btn-primary w-full `}
      >
        {t('submit-feedback')}
      </Popover.Button>
    </Popover.Panel>
  );
};

export default SupportPanel;
