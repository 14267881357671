import { FeatureStatus } from '@/config/constants';
import { BsEraserFill, BsRobot } from 'react-icons/bs';
import { FaSprayCanSparkles, FaWandMagicSparkles } from 'react-icons/fa6';
import { LuPictureInPicture2 } from 'react-icons/lu';
import {
  PiChatsTeardropBold,
  PiChatsTeardropFill,
  PiEraserFill,
  PiFlowerLotus,
  PiFlowerTulip,
  PiFlowerTulipBold,
  PiFlowerTulipDuotone,
} from 'react-icons/pi';
import { TiFlowSwitch } from 'react-icons/ti';

// This configuration is used in Navbar.

export const appConfig = [
  {
    key: 'showcase',
    path: '/showcase',
    icon: FaSprayCanSparkles,
    status: FeatureStatus.New,
  },
  {
    key: 'illustrate',
    path: '/illustrate',
    icon: FaWandMagicSparkles,
    status: FeatureStatus.New,
  },
  {
    key: 'eraser',
    path: '/eraser',
    icon: PiEraserFill,
    status: FeatureStatus.New,
  },
  {
    key: 'chat',
    path: '/chat',
    icon: PiChatsTeardropFill,
    status: FeatureStatus.Preview,
  },
] as const;
