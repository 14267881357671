export const STRIPE_SECRET_KEY = process.env.STRIPE_SECRET_KEY!;
export const STRIPE_PRICE_ID_TO_CHECKOUT = process.env
  .STRIPE_PRICE_ID_TO_CHECKOUT!;
export const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const LOCALES = ['zh', 'en'] as const;

export const LOCALE_CONFIG = {
  zh: {
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.0/svg/1f1e8-1f1f3.svg',
    status: null,
  },
  en: {
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.0/svg/1f1ec-1f1e7.svg',
    status: null,
  },
} as const;

export const DISCORD_LINK = 'https://discord.gg/d6gx8bWjUJ';

// TODO: The host of preview environment in Vercel is generated randomly, thus we cannot specify it statically
// Temporary solution is set it on server side per the first request since now all services are hosted together
// The better solution should be service registration and discovery mechanism
let penlessServerUrlInited = false;
export var PENLESS_SERVER_URL = process.env.NEXT_PUBLIC_PENLESS_SERVER_URL
  || '';

// If need to call API on server side, call this method to ensure the PENLESS_SERVER_URL is inited
export const initPenlessServerURL = (url: string): void => {
  // If in server side
  if (typeof window === 'undefined' || !window?.open) {
    if (!penlessServerUrlInited) {
      if (!PENLESS_SERVER_URL || PENLESS_SERVER_URL.trim() === '') {
        const parsedUrl = new URL(url);
        const serverUrl = parsedUrl.protocol + '//' + parsedUrl.host;

        PENLESS_SERVER_URL = serverUrl;
      }
      penlessServerUrlInited = true;
    }
  }
};

// https://nextjs.org/docs/architecture/supported-browsers
export const BROWSER_SATISFACTION = {
  Chrome: '>=64',
  Edge: '>=79',
  Firefox: '>=67',
  Opera: '>= 51',
  Safari: '>=12',
};

export enum DraggableItemTypes {
  ImageItem = 'IMAGE_ITEM',
}

export enum FeatureStatus {
  ComingSoon = 'coming-soon',
  Preview = 'preview',
  Beta = 'beta',
  New = 'new',
  Stable = 'stable',
}

export const PRODUCT_BADGE_STYLE_BY_STATUS = {
  [FeatureStatus.Stable]: '',
  [FeatureStatus.New]: 'badge-primary',
  [FeatureStatus.Beta]: 'badge-primary',
  [FeatureStatus.Preview]: 'badge-secondary',
  [FeatureStatus.ComingSoon]: 'bg-base-content border-base-content text-base-100',
};

export const PRODUCT_CARD_STYLE_BY_STATUS = {
  [FeatureStatus.Stable]: '',
  [FeatureStatus.New]: 'border-primary hover:text-primary',
  [FeatureStatus.Beta]: 'border-primary hover:text-primary',
  [FeatureStatus.Preview]: 'border-secondary hover:text-secondary',
  [FeatureStatus.ComingSoon]: 'bg-base-content border-base-content text-base-100',
};

export const IS_DEV = process.env.NODE_ENV === 'development';

// Need to keep consistent with next.config.js images.domains
export const IMAGE_DOMAINS = [
  'assets.csn.chat',
  'replicate.delivery',
  'randomuser.me',
  'nobase.penless.ai',
  'nb.penless.ai',
  'penless-1305049745.cos.na-siliconvalley.myqcloud.com',
  'skin-1305049745.cos.ap-shanghai.myqcloud.com',
  new URL(process.env.NEXT_PUBLIC_NOBASE_URL ?? 'https://nb.penless.ai')
    .hostname,
];
