import UserAvatar from '@/components/UserAvatar';
import pocketbase, { elegantUpdate } from '@/utils/pocketbase';
import { Popover } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { Record } from 'pocketbase';
import { useEffect, useState } from 'react';
import { GoCheckCircle, GoMail, GoRead } from 'react-icons/go';

const MessageBlock = ({ message, onMarkRead: _onMarkRead }: any) => {
  const t = useTranslations('Navbar');
  const [markingRead, setMarkingRead] = useState(false);
  const router = useRouter();

  const onMarkRead = (e: any, id: string) => {
    e.stopPropagation();
    setMarkingRead(true);
    fetch(`/api/messages/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ read: true }),
    })
      .then(() => _onMarkRead(id))
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setMarkingRead(false);
      });
  };

  const { name, email } = message.expand?.from || {};

  console.log(message);

  return (
    <div
      key={message.id}
      onClick={() => router.push(`/messages/`)}
      className="relative group w-full gap-2 flex items-center bg-base-content/5 hover:bg-base-content/10 border border-base-content/5 cursor-pointer px-1 py-2 rounded"
    >
      <UserAvatar user={message.expand?.from} size={7} minimal />
      <div className="flex-grow flex flex-col gap-1">
        <span className="text-xs font-bold">{name || email}</span>
        <span className="flex-grow line-clamp-2">{message.content.text}</span>
      </div>
      {markingRead ? <div className="loading loading-spinner loading-sm" /> : (
        <button
          onClick={e => onMarkRead(e, message.id)}
          data-tooltip-content={t('mark-as-read')}
          data-tooltip-id="default-tooltip"
          className="absolute hidden group-hover:block bottom-2 right-2 text-green-500/80 hover:text-green-500"
        >
          <GoCheckCircle className="w-5 h-5" />
        </button>
      )}
    </div>
  );
};

const MessagePanel = () => {
  const t = useTranslations('Navbar');
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Record[]>([]);

  const fetchRequests = () => {
    setLoading(true);
    fetch(`/api/messages?to=${pocketbase.authStore.model?.id}&count=5&read=0`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => {
        elegantUpdate(messages, data, setMessages);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const onMarkRead = (id: string) => {
    fetchRequests();
  };

  return (
    <Popover.Panel className="w-80 p-1 flex flex-col gap-1 text-sm">
      <div className="flex items-center gap-2 mb-2">
        <GoMail className="w-5 h-5" />
        <div className="font-bold">{t('messages')}</div>
      </div>
      {loading && messages.length === 0
        ? (
          <div className="w-full h-full flex flex-grow items-center justify-center">
            <div className="loading loading-sm" />
          </div>
        )
        : messages.length === 0
        ? (
          <div className="w-full h-full flex flex-col flex-grow items-center justify-center gap-2 p-4 text-base-content/50">
            <GoRead className="w-8 h-8" />
            <div>{t('no-new-message')}</div>
          </div>
        )
        : (
          messages.map(m => <MessageBlock key={m.id} message={m} onMarkRead={onMarkRead} />)
        )}
    </Popover.Panel>
  );
};

export default MessagePanel;
