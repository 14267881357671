import HoverPopover from '@/components/HoverPopover';
import { appConfig } from '@/config/app';
import { Bars3Icon, Squares2X2Icon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useTheme } from 'next-themes';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { GoComment, GoHome } from 'react-icons/go';
import LocaleSwitcher from './components/LocaleSwitcher';
import MainMenu from './components/MainMenu';
import MessageButton from './components/MessageButton';
import MessagePanel from './components/MessagePanel';
import ProductsPanel from './components/ProductsPanel';
import SupportPanel from './components/SupportPanel';
import ThemeSwitcher from './components/ThemeSwitcher';

type Props = {
  drawerToggleId?: string;
  className?: string;
};

const Navbar = ({ className, drawerToggleId }: Props) => {
  const t = useTranslations('Navbar');
  const { theme } = useTheme();
  const path = usePathname();
  const iconStyle = 'w-5 h-5';
  const [logo, setLogo] = useState(`/logo-white.png`);
  const [fullLogo, setFullLogo] = useState(`/logo-full-white.png`);

  const t2 = useTranslations('ProductsGrid');
  const getAppKey = () => appConfig.find(x => path.includes(x.path))?.key;
  const getAppName = () => {
    const productKey = getAppKey();
    return productKey ? t2(`${productKey}.title`) : '';
  };

  useEffect(() => {
    setLogo(`/logo${theme === 'night' ? '-white' : ''}.png?230905`);
    setFullLogo(`/logo-full${theme === 'night' ? '-white' : ''}.png?2309005`);
  }, [theme]);

  return (
    <div
      className={clsx(className, 'navbar p-0 gap-2 py-2', {
        'bg-navbar text-base-content border-b border-base-content/10': !className || className.indexOf('bg-') === -1,
      })}
      style={{ height: '48px' }}
    >
      <div className="navbar-start">
        {drawerToggleId && (
          <label
            htmlFor={drawerToggleId}
            className="btn btn-sm btn-ghost btn-square mx-2 lg:hidden"
          >
            <Bars3Icon className={iconStyle} />
          </label>
        )}
        <Link color="ghost" className="px-2 lg:px-4" href="/">
          <Image
            priority
            alt="penless-logo-full"
            src={fullLogo}
            width={150}
            height={32}
            className="hidden h-8 w-auto lg:block object-contain"
          />
          <Image
            priority
            alt="penless-logo-s"
            src={logo}
            width={32}
            height={32}
            className="lg:hidden w-8 h-8 object-contain"
          />
        </Link>
        <a
          href={`/${getAppKey()}`}
          className="hidden lg:flex text-lg hover:text-primary"
        >
          {getAppName()}
        </a>
      </div>
      <div className="navbar-end flex flex-1 gap-2 md:gap-4">
        <Link
          href="/agents"
          className="btn btn-sm btn-ghost btn-square"
          data-tooltip-content={t('penless-platform')}
          data-tooltip-id="default-tooltip"
        >
          <GoHome className="w-5 h-5" />
        </Link>
        <HoverPopover
          button={
            <div className="btn btn-sm btn-ghost btn-square group inline-flex items-center">
              <Squares2X2Icon className={iconStyle} />
            </div>
          }
          className="relative"
        >
          <ProductsPanel />
        </HoverPopover>
        <HoverPopover
          button={<MessageButton style={iconStyle} />}
          className="relative hidden md:block"
        >
          <MessagePanel />
        </HoverPopover>
        <HoverPopover
          className="relative hidden md:block"
          button={
            <div className="btn btn-sm btn-ghost btn-square group inline-flex items-center">
              <GoComment className={iconStyle} />
            </div>
          }
        >
          <SupportPanel />
        </HoverPopover>
        <ThemeSwitcher />
        <LocaleSwitcher />
        <MainMenu />
      </div>
    </div>
  );
};

export default Navbar;
