import { getAvatarUrl } from '@/utils/pocketbase';
import { renderToStaticMarkup } from 'react-dom/server';

const UserAvatar = ({ user, size, minimal }: any) => {
  const htmlTip = renderToStaticMarkup(
    <div className="mx-0 text-sm text-base-content p-2">
      <div>{user?.name || user?.email || ''}</div>
    </div>,
  );
  const avatarWidth = size ?? 8;

  return (
    <div className="flex items-center gap-2">
      <div data-tooltip-id="html-tooltip" data-tooltip-html={htmlTip}>
        {user?.avatar
          ? (
            <div className="avatar">
              <div className={`w-${avatarWidth} rounded-md`}>
                <img src={getAvatarUrl(user)} alt={user?.name} />
              </div>
            </div>
          )
          : (
            <div className="avatar placeholder">
              <div className={`w-${avatarWidth} rounded-full bg-secondary/20`}>
                <span>{user?.name || user?.email}</span>
              </div>
            </div>
          )}
      </div>
      {!minimal && <span className="font-bold">{user?.name || user?.email}</span>}
    </div>
  );
};

export default UserAvatar;
