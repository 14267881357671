import { FeatureStatus } from '@/config/constants';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

type Props = {
  status: FeatureStatus;
  className?: string;
};

const FeatureStatusBadge = ({ status, className }: Props) => {
  const t = useTranslations('FeatureStatusBadge');

  return status === FeatureStatus.Stable ? <></> : (
    <span className={clsx('badge badge-sm badge-ghost', className)}>
      {t(status)}
    </span>
  );
};

export default FeatureStatusBadge;
