// Refer to https://github.com/pacocoursey/next-themes/pull/171

import MoonIcon from '@heroicons/react/24/outline/MoonIcon';
import SunIcon from '@heroicons/react/24/outline/SunIcon';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useTheme } from 'next-themes';
import React, { useEffect, useState } from 'react';

const useThemeSwitcher = () => {
  const [mode, setMode] = useState('');
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setMode(theme!);
  }, [theme]);

  return { mode, setTheme };
};

const ThemeSwitcher = () => {
  const t = useTranslations('ThemeSwitcher');
  const { mode: theme, setTheme } = useThemeSwitcher();

  const toggleTheme = (e: any) => {
    e.preventDefault();
    setTheme(theme === 'night' ? 'winter' : 'night');
  };

  return (
    <div
      className="btn btn-sm btn-ghost btn-square"
      onClick={toggleTheme}
      data-tooltip-content={t('light-dark-mode')}
      data-tooltip-id="default-tooltip"
    >
      <label
        className={clsx('swap swap-rotate', {
          'swap-active': theme === 'night',
        })}
      >
        <MoonIcon className="swap-off w-5 h-5" />
        <SunIcon className="swap-on w-5 h-5" />
      </label>
    </div>
  );
};

export default ThemeSwitcher;
