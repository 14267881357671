import UserAvatar from '@/components/UserAvatar';
import pocketbase from '@/utils/pocketbase';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { Record } from 'pocketbase';
import { useEffect, useState } from 'react';
import { GoBell, GoBellFill } from 'react-icons/go';
import { toast } from 'react-toastify';

const MessageToast = ({ message }: any) => {
  const t = useTranslations('MessagePage');
  const [from, setFrom] = useState<Record>();
  useEffect(() => {
    fetch(`/api/users/${message.from}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(res => res.json())
      .then(user => {
        setFrom(user);
      })
      .catch(err => {
        console.error(err);
      });
  }, [message]);
  return (
    <div className="flex flex-col gap-2 text-sm">
      {from ? <UserAvatar user={from} size={6} /> : <div className="bg-secondary/50 w-6 h-6 my-1 rounded-full" />}
      <span className="line-clamp-3">{message.content?.text}</span>
    </div>
  );
};

const MessageButton = ({ style }: any) => {
  const t = useTranslations('Navbar');
  const [messages, setMessages] = useState<Record[]>([]);
  const fetchMessages = () => {
    fetch(`/api/messages?to=${pocketbase.authStore.model?.id}&read=0`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(res => (res.ok ? res.json() : []))
      .then(messages => {
        setMessages(messages);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchMessages();
    let unsubscribeFunc: () => Promise<void>;
    pocketbase
      .collection('messages')
      .subscribe('*', (e: any) => {
        if (e.record.to === pocketbase.authStore.model?.id) {
          if (!e.record.read) {
            toast(<MessageToast message={e.record} />, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'colored',
              toastId: e.record.id,
              onClick: () => {
                fetch(`/api/messages/${e.record.id}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  credentials: 'include',
                  body: JSON.stringify({
                    read: true,
                  }),
                })
                  .then(() => {
                    toast.dismiss(e.record.id);
                    fetchMessages();
                  })
                  .catch(err => {
                    console.error(err);
                  });
              },
            });
          }
          fetchMessages();
        }
      })
      .then(unsubscribe => {
        unsubscribeFunc = unsubscribe;
      })
      .catch(err => {
        console.error(err);
      });
    return () => {
      if (unsubscribeFunc) {
        unsubscribeFunc();
      }
    };
  }, []);

  return (
    <div
      className={clsx(
        'btn btn-sm btn-ghost btn-square group inline-flex items-center',
        { 'animate-bounce': messages.length > 0 },
      )}
    >
      {messages.length > 0
        ? (
          <>
            <GoBellFill className={clsx(style)} />
            <div className="badge badge-accent badge-xs absolute top-0 right-0">
              {messages.length}
            </div>
          </>
        )
        : <GoBell className={clsx(style)} />}
    </div>
  );
};

export default MessageButton;
