'use client';
import FeatureStatusBadge from '@/components/FeatureStatusBadge';
import { adminConfig } from '@/config/admin';
import { FeatureStatus } from '@/config/constants';
import pocketbase, { getAvatarUrl, User } from '@/utils/pocketbase';
import { Float } from '@headlessui-float/react';
import { Popover, Transition } from '@headlessui/react';
import ArrowRightOnRectangleIcon from '@heroicons/react/24/outline/ArrowRightOnRectangleIcon';
import clsx from 'clsx';
import { identity, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Record } from 'pocketbase';
import { useEffect, useState } from 'react';

interface MenuItemConfig {
  key: string;
  icon?: any;
  name: string;
  path?: string | (() => void);
  status?: FeatureStatus;
}

export const MainMenu = () => {
  const [maybeUser, setUser] = useState<O.Option<User>>(O.none);
  const router = useRouter();
  const t = useTranslations();
  const excludedKeys = ['ProfilePage', 'NotificationsPage'];
  const filteredAdminConfig = adminConfig
    .filter(v => !excludedKeys.includes(v.key))
    .map(item => ({
      ...item,
      name: t(`admin.${item.key}.title`),
    }));

  useEffect(() => {
    setUser(O.fromNullable(pocketbase.authStore.model));
    pocketbase.authStore.onChange((_, model) => {
      setUser(O.fromNullable(model));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pocketbase.authStore.model?.id]);

  const menuConfig: MenuItemConfig[] = [
    { key: 'divider1', name: '-' },
    ...filteredAdminConfig, // Merging the admin menu items.
    { key: 'divider2', name: '-' },
    {
      key: 'sign-out',
      name: t('Navbar.logout'),
      icon: ArrowRightOnRectangleIcon,
      path: () => {
        pocketbase.authStore.clear();
        router.push('/auth/login');
      },
    },
  ];

  const Avatar = () => (
    <Image
      priority
      width={32}
      height={32}
      alt="avatar"
      src={pipe(
        maybeUser,
        O.chain(user => user.avatar ? O.some(getAvatarUrl(user as Record)) : O.none),
        O.match(
          () => 'https://randomuser.me/api/portraits/lego/4.jpg',
          identity,
        ),
      )}
    />
  );

  // With user logged in
  const UserMenu = () => (
    <Popover className="relative flex items-center">
      <Float
        placement="bottom-end"
        shift={4}
        offset={6}
        enter="transition ease-out duration-150"
        enterFrom="transform origin-top-right scale-0 opacity-0"
        enterTo="transform origin-top-right scale-100 opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-0 opacity-0"
      >
        <Popover.Button className="avatar mx-1 cursor-pointer w-8 rounded-full overflow-hidden border border-transparent hover:border-base-content">
          <Avatar />
        </Popover.Button>
        <Popover.Panel className="origin-top-right z-50 rounded-md top-10 p-2 right-2 bg-base-100 dark:bg-gray-800 text-base-content shadow-lg flex flex-col w-48 lg:w-64 gap-1 text-base-content">
          <Popover.Button
            onClick={() => router.push('/admin/profile')}
            className="flex gap-2 p-2 rounded-md hover:bg-base-content/10 "
          >
            <div className="avatar w-16 rounded-full overflow-hidden">
              <Avatar />
            </div>
            <div className="flex flex-col items-start w-full gap-1">
              <div className="text-sm font-bold truncate">
                {pipe(
                  maybeUser,
                  O.match(
                    () => 'unknown',
                    user => user.name || user.username,
                  ),
                )}
              </div>
              <div className="text-xs truncate">
                {pipe(
                  maybeUser,
                  O.match(
                    () => 'unknown',
                    user => user.email,
                  ),
                )}
              </div>
            </div>
          </Popover.Button>
          {menuConfig.map(({ key, name, icon: MenuIcon, path, status }) =>
            key.startsWith('divider') ? <div key={key} className="divider my-0 py-0" /> : (
              <Popover.Button
                key={key}
                onClick={() => {
                  if (typeof path === 'string') {
                    router.push(path);
                  } else if (typeof path === 'function') {
                    path();
                  }
                }}
                className="flex justify-between w-full gap-1 p-2 rounded-md hover:bg-base-content/10"
              >
                <div className="flex items-center gap-2">
                  <MenuIcon className="w-5 h-5" />
                  <div className="text-sm">{name}</div>
                </div>
                {status && <FeatureStatusBadge status={status} />}
              </Popover.Button>
            )
          )}
        </Popover.Panel>
      </Float>
    </Popover>
  );

  const SignupButton = () => (
    <button
      className="btn btn-outline btn-primary btn-sm normal-case rounded-md m-1"
      onClick={() => router.push('/auth/login')}
    >
      {t('Navbar.sign-in')}
    </button>
  );

  return O.isNone(maybeUser) ? <SignupButton /> : <UserMenu />;
};

export default MainMenu;
